// $FlowFixMe
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { pathOr, ascend, descend, prop, sortWith, isNil } from 'ramda';
import moment from 'moment';
import { connect } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  checkLeaseEditablility,
  ELECTRONIC_SIGNING_METHOD,
  getIsDisabledMonthly,
  getLeasesOptions,
  getSelectedLease,
  parseAffordableValues,
  getMonthlyRecurringCharges,
} from '../../utils/lease-helpers.js';
import { getCurrentNonOptionalCharge } from '../../utils/affordable';

import type { GlobalState } from '../App/types';

import ApplicantHeader from './ApplicantHeader';
import ResidentHeader from './ResidentHeader';
import LeaseDataTabForm from './LeaseDataTabForm';
import {
  useAsyncFloorPlanPricing,
  useAsyncHasOpenAffordableQualification,
  useAsyncMonthlyLeaseRequiredFees,
} from './hooks';
import type { LeaseDataTabProps } from './types';
import { formatDateLocale } from '../../utils/date-helpers';
import { getApplication } from '../ApplicationProfile/selectors';

export const valueNameSort = sortWith([
  descend(prop('value')),
  ascend(prop('label')),
]);

const LeaseDataTab = ({
  allAffordableTabsApproved,
  applicationId,
  basicLeaseFees,
  doNotRenew,
  frNames,
  history,
  handleRenewal,
  handleTransfer,
  handleTransferComplete,
  hasNonFR,
  intl,
  isDisabledRenewal,
  isDisabledTransfer,
  isResident,
  labelEndDate,
  lateMethods,
  leases,
  leaseRentPercentage,
  leaseSignatureStatuses,
  leaseTerms,
  onGenerate,
  onSubmit,
  removeLease,
  residentId,
  openFiscalPeriod,
  prospectInfo,
  securityDeposits,
  selectedMonthlyOption,
  selectedProperty,
  submittingRenewal,
  transferInformation,
  utilityAllowanceId,
  softDeletedLeaseTerms,
  startTransferMessage,
  startRenewalMessage,
  underEviction,
  noticeToVacate,
  currentApplication,
  nonOptionalCharge: nonOptionalChargeFromQualification,
  application,
}: LeaseDataTabProps) => {
  const {
    assigningUnitsMoveInDates: assigningUnitsMoveInDatesFlag,
    affordableTransfers,
  } = useFlags();
  const newestLease = leases.length > 0 ? leases[0] : {};
  const [formDirty, setFormDirty] = useState(false);
  const [leaseId, setSelectedLeaseId] = useState(
    pathOr(null, ['id'], newestLease),
  );
  const moveInDate = pathOr(null, ['moveInDate'], newestLease);
  const startDate = pathOr(null, ['startDate'], newestLease);
  const initialLeaseDates = {
    moveInDate: moveInDate ? moment(moveInDate) : '',
    startDate: startDate ? moment(startDate) : '',
  };
  const [leaseDates, setLeaseDates] = useState(initialLeaseDates);

  const selectPreviousLease = () => {
    const previousLease = leases.length > 1 ? leases[1] : null;
    if (!previousLease) {
      return null;
    }
    setSelectedLeaseId(previousLease.id);
  };

  const handleLeaseChange = ({ target: { value } }) =>
    setSelectedLeaseId(value);

  const updateLeaseToNewRenewalLease = (leases: Array<Object>) => {
    const renewalLease = leases[0];
    setSelectedLeaseId(renewalLease.id);
  };

  const updateLeaseDate = (key: string, value: Object) =>
    setLeaseDates({ ...leaseDates, [key]: value });

  const lease = getSelectedLease(leases, leaseId);
  const unit = pathOr([{}], ['units'], lease)[0];
  const floorplanId = pathOr(null, ['floorPlan', 'id'], unit);
  const isAffordable = pathOr(false, ['floorPlan', 'isAffordable'], unit);
  /**
   * Hook only fires the call to the get floorplanPricings if there's a
   * floorplan and if it's affordable. Returns empty object otherwise.
   */
  const affordableFloorplanPricing = useAsyncFloorPlanPricing(
    floorplanId,
    isAffordable,
    selectedProperty,
  );
  const applicableRents = selectedProperty.setup?.rdProjectType?.rdrents || {};

  const {
    leaseMonthlyRequiredFees,
    updateRequiredFeesList,
    requiredFeesUpdated,
  } = useAsyncMonthlyLeaseRequiredFees(lease.id, lease.lockedFees);

  const { hasOpenAffordableQualifications, hasOpenGrossRentCertification } =
    useAsyncHasOpenAffordableQualification(
      selectedProperty.organizationId,
      selectedProperty.id,
      residentId,
      affordableTransfers && application?.propertyClass?.name === 'Affordable',
    );

  const affordablePricing = parseAffordableValues(
    affordableFloorplanPricing,
    leaseDates,
    lease.isRenewal,
    unit,
    utilityAllowanceId,
  );
  const nonOptionalCharge =
    nonOptionalChargeFromQualification ??
    getCurrentNonOptionalCharge(
      affordableFloorplanPricing?.nonOptionalCharges ?? [],
    );
  const affordableValues = {
    isAffordable,
    utilityAllowanceId,
    isReceivingAssistance: lease.receivesHousingAssistance,
    nonOptionalCharge,
    ...affordablePricing,
  };

  const isEditable = checkLeaseEditablility(lease, residentId);

  const leasesOptions = getLeasesOptions(leases, isResident);
  const isDisabledMonthly = getIsDisabledMonthly(
    lease,
    leasesOptions,
    residentId,
  );
  /**
   * TODO: I'd like to remove this when we move the `Monthly Lease Charges or
   * Credits` out of LDT. This parsing of `initialValues` is being done because
   * that section saves the lease, and the updated lease has null for the
   * `desiredSignatureMethod` until the page refreshes.
   */
  let desiredSignatureMethod = pathOr(
    'default',
    ['desiredSignatureMethod'],
    lease,
  );
  if (desiredSignatureMethod === ELECTRONIC_SIGNING_METHOD && hasNonFR) {
    desiredSignatureMethod = 'default';
  }
  const initialValuesStandard = {
    ...lease,
    isReceivingAssistance: lease.receivesHousingAssistance,
    desiredSignatureMethod,
    overrideLeaseExpirationLimit: !isNil(
      lease.overrideLeaseExpirationLimitUpdatedAt,
    ),
  };

  const initialValuesFlag = {
    ...lease,
    isReceivingAssistance: lease.receivesHousingAssistance,
    desiredSignatureMethod,
    overrideLeaseExpirationLimit: !isNil(
      lease.overrideLeaseExpirationLimitUpdatedAt,
    ),
    moveInDate:
      lease?.moveInDate ||
      currentApplication?.au?.lease?.moveInDate ||
      (prospectInfo?.prospectPreferences?.moveInDateScheduled
        ? formatDateLocale(
            prospectInfo?.prospectPreferences?.moveInDateScheduled,
          )
        : null),
  };

  const initialValues = assigningUnitsMoveInDatesFlag
    ? initialValuesFlag
    : initialValuesStandard;

  const configuredUnitFees = getMonthlyRecurringCharges(unit);
  let monthlyRecurringCharges = lease.lockedFees
    ? leaseMonthlyRequiredFees
    : configuredUnitFees;

  // Sort by amount//name
  monthlyRecurringCharges = valueNameSort(monthlyRecurringCharges);
  const activeMonthlyRecurringFees = monthlyRecurringCharges.filter(
    (fee) => !fee.isRemoved,
  );
  return (
    <div className="container-fluid">
      {!residentId && <ApplicantHeader frNames={frNames} />}
      {residentId && (
        <ResidentHeader
          applicationId={applicationId}
          application={application}
          doNotRenew={doNotRenew}
          formDirty={formDirty}
          history={history}
          handleLeaseChange={handleLeaseChange}
          handleRenewal={handleRenewal}
          handleTransfer={handleTransfer}
          handleTransferComplete={handleTransferComplete}
          hasOpenAffordableQualifications={hasOpenAffordableQualifications}
          hasOpenGrossRentCertification={hasOpenGrossRentCertification}
          isDisabledRenewal={isDisabledRenewal}
          isDisabledTransfer={isDisabledTransfer}
          isResident={isResident}
          lease={lease}
          leasesOptions={leasesOptions}
          leaseTerms={leaseTerms}
          removeLease={removeLease}
          residentId={residentId}
          selectPreviousLease={selectPreviousLease}
          submittingRenewal={submittingRenewal}
          updateLeaseToNewRenewalLease={updateLeaseToNewRenewalLease}
          startTransferMessage={startTransferMessage}
          startRenewalMessage={startRenewalMessage}
          underEviction={underEviction}
          noticeToVacate={noticeToVacate}
        />
      )}
      <LeaseDataTabForm
        affordableValues={affordableValues}
        applicableRents={applicableRents}
        applicationId={applicationId}
        basicLeaseFees={basicLeaseFees}
        frNames={frNames}
        hasNonFR={hasNonFR}
        initialValues={{
          ...initialValues,
          utilityAllowanceAmount: affordablePricing?.utilityAllowanceAmount,
          monthlyRecurringCharges: activeMonthlyRecurringFees,
        }}
        allAffordableTabsApproved={allAffordableTabsApproved}
        householdId={application?.householdId}
        intl={intl}
        isDisabledMonthly={isDisabledMonthly}
        isEditable={isEditable}
        labelEndDate={labelEndDate}
        lateMethods={lateMethods}
        lease={lease}
        priorLease={leases[1] || {}}
        leaseRentPercentage={leaseRentPercentage}
        leaseSignatureStatuses={leaseSignatureStatuses}
        leaseTerms={leaseTerms}
        softDeletedLeaseTerms={softDeletedLeaseTerms}
        onGenerate={onGenerate}
        onSubmit={onSubmit}
        openFiscalPeriod={openFiscalPeriod}
        prospectInfo={prospectInfo}
        residentId={residentId}
        securityDeposits={securityDeposits}
        selectedMonthlyOption={selectedMonthlyOption}
        setFormDirty={setFormDirty}
        transferInformation={transferInformation}
        unit={unit}
        updateLeaseDate={updateLeaseDate}
        monthlyRecurringCharges={monthlyRecurringCharges}
        updateRequiredFeesList={updateRequiredFeesList}
        requiredFeesUpdated={requiredFeesUpdated}
        noticeToVacate={noticeToVacate}
      />
    </div>
  );
};

export const mapStateToProps = (state: GlobalState): Object => {
  const {
    app: { selectedProperty },
  } = state;
  return {
    selectedProperty,
    currentApplication: getApplication(state),
  };
};

export default connect(mapStateToProps)(injectIntl(LeaseDataTab));
