// Hooks
import useUniqueTableName from '../../hooks/useUniqueTableName';
import useHousingAssistanceVouchersQuery from './hooks.useHousingAssistanceVouchersQuery';
import useRows from './hooks.useRows';
import useModalProps from '../../hooks/useModalProps';
import {
  useTableFilterSortSearchManager,
  useTableFilterSortData,
  useTableManageColumns,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';

import { HEADERS, PROPERTY_PATH_MAP } from './constants';

const useHousingAssistanceVoucher = ({
  organizationId,
  propertyId,
  householdId,
}) => {
  const name = useUniqueTableName('household-housing-choice-vouchers');
  const {
    data: results,
    isLoading,
    refetch: refetchVouchers,
  } = useHousingAssistanceVouchersQuery({
    organizationId,
    propertyId,
    householdId,
  });

  const headers = HEADERS;

  const {
    filterState,
    filterTypeState,
    dateState,
    order,
    orderBy,
    handleSortChange,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleDateSubmit,
    searchState,
  } = useTableFilterSortSearchManager({
    name,
    initialOrderBy: 'voucherStartDate',
    initialOrder: 'desc',
    headers,
  });

  const filteredResults = useTableFilterSortData({
    results,
    order,
    orderBy,
    filterState,
    filterTypeState,
    searchState,
    dateState,
    PROPERTY_PATH_MAP,
  });

  const modalProps = useModalProps();

  const rows = useRows({
    organizationId,
    propertyId,
    householdId,
    handleOpenModal: modalProps.handleOpen,
    handleOpenConfirmModal: modalProps.handleOpenConfirmModal,
    housingAssistanceVouchers: filteredResults,
  });

  const {
    allColumnsHidden,
    columnOptions,
    filteredHeaders,
    selectedColumns,
    handleColumnChange,
  } = useTableManageColumns({
    name,
    headers,
    firstRow: rows[0],
  });

  return {
    modalProps,
    // Table
    handleSortChange,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleDateSubmit,
    isLoading,
    allColumnsHidden,
    columnOptions,
    filteredHeaders,
    selectedColumns,
    handleColumnChange,
    count: rows.length ?? 0,
    totalCount: results?.length ?? 0,
    name,
    order,
    orderBy,
    // Data
    refetchVouchers,
    rows,
  };
};

export default useHousingAssistanceVoucher;
