import { defineMessages } from 'react-intl';

const messages = defineMessages({
  requiredForGenerateLease: {
    id: 'App.LeaseDataTabForm.RequiredForGenerateLease',
    defaultMessage: 'This field is required to generate a lease',
  },
  validDatePast: {
    id: 'App.LeaseDataTabForm.validDatePast',
    defaultMessage: "Can't select a date in the past",
  },
  validDatePastYear: {
    id: 'App.LeaseDataTabForm.validDatePastYear',
    defaultMessage: 'Date cannot be more than 1 year in the past.',
  },
  validDate16MonthsPast: {
    id: 'App.LeaseDataTabForm.validDate16MonthsPast',
    defaultMessage: 'Date cannot be more than 16 months in the past.',
  },
  validDateAfterMoveIn: {
    id: 'App.LeaseDataTabForm.validDateAfterMoveIn',
    defaultMessage: "Can't select a date after move in date",
  },
  validDateBeforeMoveIn: {
    id: 'App.LeaseDataTabForm.validDateBeforeMoveIn',
    defaultMessage: "Can't select a date before move in date",
  },
  validDateBeforeStartDate: {
    id: 'App.LeaseDataTabForm.validDateBeforeStartDate',
    defaultMessage: "Can't select a date before start date",
  },
  validDateBeforeMoveOutDate: {
    id: 'App.LeaseDataTabForm.validDateBeforeMoveOutDate',
    defaultMessage: "Can't select a date before the last lease ends",
  },
  validDateBeforeEndDate: {
    id: 'App.LeaseDataTabForm.validDateBeforeEndDate',
    defaultMessage: "Can't select a date before previous lease's end date",
  },
  /* eslint-disable */
  rentIsTooHigh: {
    id: 'App.ResidentProfile.RentIsTooHigh',
    defaultMessage:
      'Lease Rent Amount must be less than the Gross Rent Limit minus the Utility Allowance unless receiving housing assistance',
  },
  rentIsTooHighWithNonOptionalCharge: {
    id: 'App.ResidentProfile.RentIsTooHighWithNonOptionalCharge',
    defaultMessage:
      'Lease Rent Amount must be less than the Gross Rent Limit minus the Utility Allowance and Non-Optional Charges unless receiving housing assistance',
  },
  rentPositive: {
    id: 'App.ResidentProfile.RentPositive',
    defaultMessage: 'Lease Rent Amount must be positive.',
  },
  rentWithinLeasedRentPercentage: {
    id: 'App.ResidentProfile.RentWithinLeasedRentPercentage',
    defaultMessage:
      'Lease Rent Amount must be within {leaseRentPercentage}% of Quoting Rent Amount.',
  },
  noteRentErrorWithUA: {
    id: 'App.ResidentProfile.noteRentErrorWithUA',
    defaultMessage:
      'Lease Rent Amount must be less than the Note Rent minus the Utility Allowance',
  },
  noteRentError: {
    id: 'App.ResidentProfile.noteRentError',
    defaultMessage: 'Lease Rent Amount must be less than the Note Rent',
  },
  hudGrossRentandNoteError: {
    id: 'App.ResidentProfile.hudGrossRentError',
    defaultMessage:
      'Lease Rent Amount must be less than the HUD Gross Rent Limit and Note Rent',
  },
  hudGrossRentAndNoteErrorWithUA: {
    id: 'App.ResidentProfile.hudGrossRentErrorWithUA',
    defaultMessage:
      'Lease Rent Amount must be less than the HUD Gross Rent Limit miuns Utility Allowance and Note Rent minus the Utility Allowance',
  },
  /* eslint-enable */
  leaseEndDateMustBeEarlierThanRenewalStart: {
    id: 'App.ResidentProfile.leaseEndDateMustBeEarlierThanRenewalStart',
    defaultMessage:
      'Lease End Date must be earlier than the start date of the Renewal Lease',
  },
  beforeFiscalPeriod: {
    id: 'App.ResidentProfile.leaseEndDateMustBeEarlierThanRenewalStart.beforeFiscalPeriod',
    defaultMessage:
      'You cannot select a Scheduled Move-In date that is in a prior/closed fiscal period.',
  },
  leaseExpirationLimitNoMonthsAvailable: {
    id: 'App.LeaseDataTabForm.LeaseExpirationLimitNoMonthsAvailable',
    defaultMessage:
      '{endDate} is over the Lease Expiration Limit. There are no limits available within 61 days (past or future).',
  },
  leaseExpirationLimitOverLimit: {
    id: 'App.LeaseDataTabForm.LeaseExpirationLimitOverLimit',
    defaultMessage:
      '{endDate} is over the Lease Expiration Limit. These months are available: {availableMonths}',
  },
  leaseExpirationLimitError: {
    id: 'App.LeaseDataTabForm.LeaseExpirationLimitError',
    defaultMessage: 'Unable to validate end date. Error: {message}',
  },
  dateMustBeOnOrAfterRenewalDate: {
    id: 'App.LeaseDataTabForm.DateMustBeOnOrAfterRenewalDate',
    defaultMessage: 'Start Date cannot be after Anticipated Move-Out Date',
  },
  leaseRent236ErrorWithUA: {
    id: 'App.LeaseDataTabForm.LeaseRent236ErrorWithUA',
    defaultMessage:
      'Lease Rent Amount must be less than the Market Rent minus the Utility Allowance.',
  },
  leaseRent236Error: {
    id: 'App.LeaseDataTabForm.LeaseRent236Error',
    defaultMessage: 'Lease Rent Amount must be less than the Market Rent.',
  },
  leaseRentBannerText: {
    id: 'App.LeaseDataTabForm.LeaseRentBannerText',
    defaultMessage:
      // eslint-disable-next-line max-len
      'Saving any changes will result in new monthly lease transactions being automatically created. If you complete a housing voucher later, the transactions will be updated automatically at that point in time.',
  },
});

export default messages;
