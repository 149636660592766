import { useState, useMemo, useCallback, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toastr } from 'react-redux-toastr';
import { usePrevious } from '@fortress-technology-solutions/fortress-component-library/hooks';
import { formatDateDB } from '@fortress-technology-solutions/fortress-component-library/utils';
import moment from 'moment';

import LeaseService from '../../../../services/leaseService';

const leaseService = new LeaseService();

export const useRentSteps = ({
  lease,
  receivingAssistance,
  closeModal,
  refreshLDT,
  open,
}) => {
  const [rentSteps, setRentSteps] = useState([]);
  const [rentStepsToDelete, setRentStepsToDelete] = useState([]);
  const [rentStepsToCreate, setRentStepsToCreate] = useState([]);
  const [rentStepsToUpdate, setRentStepsToUpdate] = useState([]);
  const [receivingAsstState, setReceivingAsstState] = useState(false);

  const { data: initialRentSteps } = useFetchSavedRentSteps({
    leaseId: lease.id,
    lease,
  });
  const prevLease = usePrevious(lease);
  const previousAsstState = usePrevious(receivingAssistance);

  const resetRentSteps = useCallback(() => {
    setRentSteps(
      initialRentSteps.map((step, idx) => {
        return {
          ...step,
          idx,
        };
      }),
    );
    setRentStepsToCreate([]);
    setRentStepsToDelete([]);
    setRentStepsToUpdate([]);
  }, [initialRentSteps]);

  function handleCloseModal() {
    refreshLDT();
    resetRentSteps();
    setReceivingAsstState(receivingAssistance);
    closeModal();
  }

  const { saveRentSteps, rentStepsSaving } = useSaveRentSteps({
    leaseId: lease.id,
    rentSteps,
    rentStepsToDelete,
    rentStepsToUpdate,
    rentStepsToCreate,
    receivingAssistance: receivingAsstState,
    onSuccess: handleCloseModal,
  });

  useEffect(() => {
    resetRentSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (prevLease?.id !== lease?.id) {
      resetRentSteps();
    }
  }, [lease?.id, prevLease?.id, resetRentSteps]);

  useEffect(() => {
    if (receivingAssistance !== previousAsstState) {
      setReceivingAsstState(receivingAssistance);
    }
  }, [receivingAssistance, previousAsstState, setReceivingAsstState]);

  return {
    rentSteps,
    setRentSteps,
    resetRentSteps,
    saveRentSteps,
    receivingAsstState,
    setReceivingAsstState,
    rentStepsSaving,
    setRentStepsToDelete,
    setRentStepsToCreate,
    setRentStepsToUpdate,
    rentStepsToUpdate,
  };
};

const useFetchSavedRentSteps = ({ leaseId, lease }) => {
  const initialRentSteps = useMemo(
    () => [
      {
        idx: 0,
        id: 0,
        leaseId: lease.id,
        startDate: lease.startDate,
        endDate: lease.endDate,
        amount: lease.leasedRent,
      },
    ],
    [lease],
  );
  const { data, isLoading } = useQuery(
    'rentSteps',
    () => leaseService.getLeaseRentSteps(leaseId),
    {
      enabled: true,
      refetchOnWindowFocus: false,
      initialData: [],
    },
  );

  return { data: data.length > 0 ? data : initialRentSteps, isLoading };
};

const useSaveRentSteps = ({
  leaseId,
  rentSteps,
  rentStepsToDelete,
  rentStepsToUpdate,
  rentStepsToCreate,
  receivingAssistance = false,
  onSuccess,
}) => {
  const queryClient = useQueryClient();
  const saveRentSteps = useCallback(() => {
    return leaseService.saveLeaseRentSteps(
      leaseId,
      rentSteps,
      rentStepsToDelete,
      rentStepsToUpdate,
      rentStepsToCreate,
      receivingAssistance,
    );
  }, [
    leaseId,
    rentSteps,
    rentStepsToDelete,
    rentStepsToUpdate,
    rentStepsToCreate,
    receivingAssistance,
  ]);

  const saveRentStepsMutation = useMutation(saveRentSteps, {
    onSuccess: () => {
      toastr.success('Success', 'Rent steps saved successfully');
      queryClient.invalidateQueries('rentSteps');
      onSuccess();
    },
    onError: () => {
      toastr.error('Error', 'Failed to save rent steps');
    },
  });
  return {
    saveRentSteps: saveRentStepsMutation.mutate,
    rentStepsSaving: saveRentStepsMutation.isLoading,
  };
};

export const getUpdatedStepEndDate = ({
  stepStartDate,
  isCurrentLastStep,
  currentLeaseEndDate,
  nextRentStepStartDate,
  renewalOngoing,
  renewalLeaseStartDate,
}) => {
  const endDate = (() => {
    const endDate = isCurrentLastStep
      ? moment(currentLeaseEndDate)
      : moment(nextRentStepStartDate).subtract(1, 'days');
    const stepStartDateIsOnOrAfterCurrentLeaseEndDate =
      moment(stepStartDate).isSameOrAfter(currentLeaseEndDate);
    const endDateIsAfterCurrentLeaseEndDate =
      endDate.isSameOrAfter(currentLeaseEndDate);

    if (!renewalOngoing && stepStartDateIsOnOrAfterCurrentLeaseEndDate) {
      return null;
    }
    if (
      renewalOngoing &&
      (stepStartDateIsOnOrAfterCurrentLeaseEndDate ||
        endDateIsAfterCurrentLeaseEndDate)
    ) {
      return moment(renewalLeaseStartDate).subtract(1, 'days');
    } else {
      return endDate;
    }
  })();

  return endDate ? formatDateDB(endDate, false) : null;
};
